import Button from "../Button/Button";
import "./WhatsAppButton.css";
export default function () {

  var mobile = false;
  if( /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    mobile = true;
   }
  return (
    <a href={`https://${mobile ? 'api': 'web'}.whatsapp.com/send?phone=524443719164&text=Requiero información sobre la renta de sanitarios`} target = "_blank">
      <button className="my-wa-button">
        <div className="my-wa-button-content">
          <i className="fab fa-whatsapp pr-2 fa-2x"></i>
          <div>¡Envianos un WhatsApp!</div>
        </div>
      </button>
    </a>
  );
}
