import { Col, Container, Form, Input, Row } from "reactstrap";
import headerImg from "../assets/12223412.png";
import eventos from "../assets/70515.jpg";
import logo from "../assets/SANI-LOGO2-SMALL.png";
import construccion from "../assets/114281.jpg";
import drenaje from "../assets/5623.jpg";
import trampa from "../assets/44356.jpg";

import help from "../assets/4435.jpg";
import "./Landing.css";
import Button from "../components/Button/Button";
import Footer from "./Footer/Footer";
import WhatsAppButton from "./WhatsAppButton/WhatsAppButton";
import ContactForm from '../components/ContactForm'

export default function () {

  return (
    <Container fluid>
      <WhatsAppButton />
      <Row className="bg-primary">
        <Col sm="12">
          <Row sm="12" className="justify-content-center">
          <Col sm = {12}>
            <img src = {logo} width = "250px"/>
          </Col>
            <Col className="text-white my-title font-weight-bold pb-4 pt-4" sm="10">
              SANITARIOS PORTÁTILES DE VILLA DE REYES
            </Col>
            <Col className="text-white   my-light-heading  pb-5" sm="10">
              Contamos con 4 años de experiencia ofreciendo soluciones en
              ingeniería sanitaria, con personal en constante capacitación y
              tecnología de punta.
            </Col>

            {/* <Col className="text-white" sm="12">
              <Row>
                <Col sm="12">Renta de sanitarios portatiles</Col>
                <Col sm="12">Desague de fosas septicas</Col>
                <Col sm="12">Desague de trampas de grasas</Col>
                <Col sm="12">
                  Limpieza de drenajes comerciales e industriales
                </Col>
              </Row>
            </Col> */}
          </Row>
        </Col>
        <Col sm="12" className="p-0">
          <img src={headerImg} width="100%" />
        </Col>
      </Row>

      <Container id = "servicios">
        <Row className="text-primary py-5  justify-content-center">
          <Col sm="12" className="my-title font-weight-normal">
            ESTAMOS PARA AYUDARLE
          </Col>
          <Col sm="6" className="">
            <img src={help} width="100%" />
          </Col>
          <Col className="my-light-heading " sm="12">
            Conozca nuestros servicios en{" "}
            <a href="#rentadesanitarios">
              <b>Renta de Sanitarios Portátiles</b> para
              <b> Eventos</b>
            </a>
            ,{" "}
            <a href="#rentadesanitarios">
              <b> Renta de Sanitarios Portátiles</b> para <b>Construcción</b>
            </a>
            ,{" "}
            <a href="#limpiezadrenajes">
              <b> Destape y Limpieza de Drenajes Comerciales e Industriales</b>
            </a>
            ,{" "}
            <a href="#desaguedefosas">
              <b> Desagüe de Fosas Sépticas</b> y <b>Trampas de Grasa</b>{" "}
            </a>
          </Col>
        </Row>
      </Container>
      <Row
        id="rentadesanitarios"
        className="bg-primary text-white flex-row-reverse"
      >
        <Col lg="5" className="p-0">
          <img src={eventos} className="my-service-image" />
        </Col>
        <Col md="12" lg="7 " className="py-5 px-4 text-lg-left">
          <Row className="" sm="12">
            <Col sm="12" className="my-title font-weight-normal  mb-5 ">
              RENTA DE SANITARIOS PORTÁTILES PARA EVENTOS
            </Col>
            <Col sm="12" className="h2 mb-5 text-secondary">
              Para cualquier evento y ocasión
            </Col>
            <Col sm="12" className="my-light-heading   text- pr-lg-5 mt-2">
              Contamos la solución ideal para todo tipo de necesidades; desde
              casetas estándar para exigencias básicas, hasta módulos con los
              más rigurosos estándares de lujo y confort. <br></br> <br></br>{" "}
              Nuestro servicio es rápido y eficaz, tanto en la puesta a punto de
              los sanitarios portátiles, como en la recolección.
            </Col>
          </Row>
        </Col>
      </Row>
      <Row id="limpiezadrenajes" className="text-primary">
        <Col md="12" lg="5" className="p-0">
          <img className="my-service-image" src={construccion} />
        </Col>
        <Col md="12" lg="7" className="py-5 px-4 text-lg-right">
          <Row className="" sm="12">
            <Col sm="12" className="my-title  font-weight-normal   mb-5">
              RENTA DE SANITARIOS PORTÁTILES PARA CONSTRUCCIÓN{" "}
            </Col>
            <Col sm="12" className="h2 mb-5 text-secondary">
              Con todo lo que necesitas para tu obra
            </Col>
            <Col sm="12" className="my-light-heading pl-lg-5 mt-2">
              Ofrecemos el servicio de casetas construidas en polietileno de
              alto impacto con mingitorio, Indicador de casetas para hombres y
              mujeres, indicador de uso de abierto/cerrado en la puerta de la
              caseta, WC con tapa y rejilla de ventilación de olores.
              <br></br>
              <br></br>
              Además ofrecemos el servicio de tanques sépticos para oficinas
              móviles.
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        id="desaguedefosas"
        className="bg-primary text-white flex-row-reverse"
      >
        <Col lg="5" className="p-0">
          <img src={trampa} className="my-service-image" />
        </Col>
        <Col md="12" lg="7 " className="py-5 px-4 text-lg-left">
          <Row className="" sm="12">
            <Col sm="12" className="my-title  font-weight-normal  mb-5 ">
              DESAGÜE DE FOSAS SÉPTICAS Y TRAMPAS DE GRASA
            </Col>
            <Col sm="12" className="h2 pb-5 text-secondary">
              Somos una empresa comprometida con el medio ambiente
            </Col>
            <Col sm="12" className="my-light-heading pr-lg-5 pt-2">
              Desagüe de Fosas Sépticas y Trampas de Grasa, Limpieza de Lodos
              Industriales, etc. El residuo es succionado de la fosa y se lleva
              a las plantas tratadoras de aguas residuales.
              <br></br>
              <br></br>
              contamos con un excelente equipo de succión de alto vacío para la
              recolección de residuos de grasas vegetales provenientes de
              trampas de grasa.
            </Col>
          </Row>
        </Col>
      </Row>
      <Row id="limpiezadrenajes" className="text-primary">
        <Col md="12" lg="5" className="p-0">
          <img className="my-service-image" src={drenaje} />
        </Col>
        <Col md="12" lg="7" className="py-5 px-4 text-lg-right">
          <Row className="" sm="12">
            <Col sm="12" className="my-title font-weight-normal  mb-5 ">
              DESTAPE Y LIMPIEZA DE DRENAJES COMERCIALES E INDUSTRIALES{" "}
            </Col>
            <Col sm="12" className="h2 mb-5 text-secondary">
              Con tecnologia de punta y profesionales capacitados.
            </Col>
            <Col sm="12" className="my-light-heading  pl-lg-5 mt-2">
              Ofrecemos un servicio de Destape y Limpieza de Drenaje, Limpieza
              de Lodos Industriales, Limpieza de Carcamo, Desazolve de Cañerías,
              Desazolve de Tuberías, etc. Para garantizar el buen funcionamiento
              en sus instalaciones.
              <br></br>
              <br></br>
              Nuestro personal localizará la línea de drenaje principal y
              llevará a cabo un trabajo minucioso en el lavado de rejillas y
              coladeras, hasta dejarlas destapadas y en óptimo estado.
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="bg-primary text-white flex-row-reverse py-5 px-4">
        <Container className="">
          <Col sm="12" className="my-title font-weight-normal mb-5">
            NUESTRO SERVICIO
          </Col>
          <Col sm="12" className="mt-5 pt-5">
            <Row sm="12" className="pb-sm-5">
              <Col sm="6" className="my-4 my-sm-0 pb-3">
                <Row className="text-center">
                  <Col sm="12">
                    <i className="fad fa-badge-check fa-4x"></i>
                  </Col>
                  <Col
                    sm="12"
                    className="text-left h3 text-center text-secondary"
                  >
                    Calidad
                  </Col>
                  <Col sm="12" className="text-left body2 text-center">
                    Tenemos la tecnología y el equipo para garantizarle una servicio de la más alta calidad.
                  </Col>
                </Row>
              </Col>

              <Col sm="6" className="my-4 my-sm-0 pb-3">
                <Row className="text-center">
                  <Col sm="12">
                    <i className="fad fa-shipping-timed fa-4x"></i>
                  </Col>
                  <Col sm="12" className="text-left h3 text-center text-secondary">
                    Puntualidad
                  </Col>
                  <Col sm="12" className="text-left body2 text-center">
                    Sabemos que su tiempo es importante, por eso lo tomamos con
                    la seriedad que usted merece.

                  </Col>
                </Row>
              </Col>
            </Row>
            <Row sm="12">
              <Col sm="6" className="my-4 my-sm-0 pb-3">
                <Row className="text-center">
                  <Col sm="12">
                    <i className="fad fa-comments fa-4x"></i>
                  </Col>
                  <Col sm="12" className="text-left h3 text-center text-secondary">
                    Atención personalizada
                  </Col>
                  <Col sm="12" className="text-left body2 text-center">
                  La atención a y la satisfacción de nuestros
                    clientes es nuestro principal objetivo.
                  </Col>
                </Row>
              </Col>
              <Col sm="6" className="my-4 my-sm-0 pb-3">
                <Row className="text-center">
                  <Col sm="12">
                    <i className="fad fa-hand-holding-seedling fa-4x"></i>
                  </Col>
                  <Col sm="12" className="text-left h3 text-center text-secondary">
                    Conciencia ambiental
                  </Col>
                  <Col sm="12" className="text-left body2 text-center">
                    Nuestros procesos estan diseñados pensando en las mas exigentes normas ambientales.
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Container>
      </Row>

      <Container className = "py-5" id = "contacto">
        <Row className="text-primary py-5 px-4 row justify-content-center ">
          <Col md="6" className="mb-5 mb-md-0">
            <Row>
              <Col sm="12" className="my-title font-weight-normal font-weigth-bold pb-4">
                CONTÁCTENOS
              </Col>
              <Col sm="12" className="h2 pb-3">
                Nos complacería saber de usted, envíenos un mensaje.
              </Col>
              <Col sm="12" className="body2">
               Si tiene alguna duda sobre el servicio, necesita una cotización o tiene quejas o sugerencias.
              </Col>
            </Row>
          </Col>
          <Col sm="10" md="6" className="px-lg-5 ">
            <ContactForm>

            </ContactForm>
          </Col>
        </Row>
      </Container>

      <Footer />
    </Container>
  );
}
