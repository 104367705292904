import "./App.css";
import NavBar from './components/NavBar'
import Landing from './components/Landing'

function App() {
  return (
    <div className="App">
      <NavBar></NavBar>
      <Landing>

      </Landing>
    </div>
  );
}

export default App;
