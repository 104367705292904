import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Form, Row, Input, Col, Button } from "reactstrap";

const ContactForm = () => {
  function submitMessage(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_USER_ID
      )
      .then((res) => {
        setMessageSent('sent');
      })
      .catch((error) => {
        setMessageSent('error');
      });
  }

  const [messageSent, setMessageSent] = useState("none");

  return messageSent === "none" ? (
    <Form className="px-xl-5" onSubmit={submitMessage}>
      <Row className="my-form-body " sm="12">
        <Col sm="12" className="my-2">
          <Input
            type="text"
            name="from_name"
            placeholder="Nombre"
            className="my-input"
          ></Input>
        </Col>
        <Col sm="12" className="my-2">
          <Input
            name="from_phone"
            type="text"
            placeholder="Telefono"
            className="my-input"
          ></Input>
        </Col>
        <Col sm="12" className="my-2">
          <Input
            name="from_mail"
            type="text"
            placeholder="Correo electronico"
            className="my-input"
          ></Input>
        </Col>
        <Col sm="12" className="my-2">
          <Input
            name="message"
            rows="5"
            type="textarea"
            placeholder="Mensaje"
            className="my-input"
          ></Input>
        </Col>
        <Col sm="12" className="my-2">
          <Button className="my-button bg-primary" color="primary">
            ENVIAR
          </Button>
        </Col>
      </Row>
    </Form>
  ) : messageSent === "sent" ? (
    <Row>
      <Col sm={12}>
        <i className="fad fa-comment-smile fa-7x"></i>
      </Col>
      <Col sm={12} className="h2">
        ¡Muchas Gracias!
      </Col>
      <Col sm={12} className="body2">
        Hemos recibido su mensaje
      </Col>
    </Row>
  ) : (
    <Row>
      <Col sm={12}>
        <i className="fad fa-comment-times fa-7x"></i>
      </Col>
      <Col sm={12} className="h2 text-danger">
        ¡Ocurrio un error!
      </Col>
      <Col sm={12} className="body2 text-danger">
        No se pudo enviar su mensaje
      </Col>
    </Row>
  );
};

export default ContactForm;
