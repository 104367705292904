import { useState } from "react";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import Logo from '../assets/SANI-LOGO2-SMALL.png'


export default function () {
    const [collapsed, setCollapsed] = useState(true)

  return (
    <Navbar color="primary" expand = "md" light>
      <NavbarBrand href = "/"><img width = "80px" src = {Logo}/></NavbarBrand>
      <NavbarToggler className = "text-white"  onClick = { () => setCollapsed(!collapsed)} className = "mr-2"/>
      <Collapse isOpen = {!collapsed} navbar>
        <Nav navbar  className = "ml-auto">
          <NavItem>
            <NavLink className = "text-white" href = "/">Inicio</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className = "text-white" href = "/#servicios">Servicios</NavLink>
          </NavItem>
          <NavItem>
            <NavLink className = "text-white" href = "/#contacto">Contacto</NavLink>
          </NavItem>
        </Nav>
      </Collapse>
      
    </Navbar>
  );
}
