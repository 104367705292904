import { Row, Col } from "reactstrap";
import logo from "../../assets/SANI-LOGO2-SMALL.png";
export default function ({}) {
  return (
    <Row className="bg-primary text-white">
      <Col sm="12" className="pt-4 px-5 text-left">
        <Row sm="12">
          <Col sm="6" className="mb-5 mb-sm-0">
            <Row sm="12">
              <Col sm="12" className="mb-4 font-weight-bold">
                CONTACTO
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="1">
                <i className="fas fa-envelope fa-2x"></i>
              </Col>
              <Col sm="11" className="pl-md-4 pl-xl-0">
                contacto@sanireyes.com.mx
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="1">
                <i className="fas fa-map-marker-alt fa-2x"></i>
              </Col>
              <Col sm="11" className="pl-md-4 pl-xl-0">
                Aranda # 34, Villa de Reyes, San Luis Potosi
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="1">
                <i className="fas fa-phone-alt fa-2x"></i>
              </Col>
              <Col sm="11" className="pl-md-4 pl-xl-0">
                444 857 48 86 y 444 371 91 64{" "}
              </Col>
            </Row>
          </Col>
          <Col sm="6" className="mb-5 mb-sm-0">
            <Row>
              <Col sm="12" className="mb-4 font-weight-bold">
                SIGUENOS EN NUESTRAS REDES
              </Col>
            </Row>
            <a className="mt-3 row link-white" href = "https://www.facebook.com/Sanireyes-108085571450612" target = "_blank">
                <Col sm="1">
                  <i className="fab fa-facebook fa-2x"></i>
                </Col>
                <Col sm="11" className="pl-md-4 pl-xl-0">
                  facebook.com/Sanireyes
                </Col>
            </a>
            <a className="mt-3 row link-white" href = "https://www.instagram.com/sani_reyes/?hl=es-la" target = "_blank">
                <Col sm="1">
                  <i className="fab fa-instagram fa-2x"></i>
                </Col>
                <Col sm="11" className="pl-md-4 pl-xl-0">
                  instagram.com/sani_reyes
                </Col>
            </a>
            <Row className="mt-3">
              <Col sm="12">
                <img src={logo} width="120px" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col sm="12" className="text-center py-3">
        SANITARIOS DE VILLA DE REYES S.A. DE C.V &copy;{" "}
        {new Date().getFullYear()}
      </Col>
    </Row>
  );
}
